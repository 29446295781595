import React, { Component } from "react";
import axios from "axios";
import SunCalc from "suncalc";
import { toast } from "react-toastify";

import Input from "../../hoc/UI/Input/Input";
import MainWeatherReport from "../../components/WeatherReport/MainWeatherReport/MainWeatherReport";
import WeatherReport from "../../components/WeatherReport/WeatherReport";
import SubWeatherReport from "../../components/WeatherReport/SubWeatherReport/SubWeatherReport.js";
import TwentyFourHTable from "../../components/WeatherReport/WeatherTable/24hTable/TwentyFourHTable";
import WeeklyTable from "../../components/WeatherReport/WeatherTable/WeeklyTable/WeeklyTable";
import TableCell from "../../hoc/UI/TableCell/TableCell";
import FlexBreak from "../../hoc/UI/FlexBreak/FlexBreak";
import Map from "../../hoc/UI/Map/Map";

import SearchIcon from "@mui/icons-material/Search";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";
import DeviceThermostatOutlinedIcon from "@mui/icons-material/DeviceThermostatOutlined";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import BeachAccessOutlinedIcon from "@mui/icons-material/BeachAccessOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import classes from "./WeatherForecast.module.css";

class WeatherForecast extends Component {
  state = {
    forecastData: [],
    geoJson: [],
    searchedFor: "",
    lat: 47.49,
    long: 19.04,
    city: "Budapest",
    center: [47.49, 19.04],
    loading: true,
  };

  componentDidMount() {
    if (localStorage.previousSearch) {
      this.searchClicked(localStorage.previousSearch);
    } else {
      this.searchClicked("Budapest");
    }
  }

  gatherWeatherInfo = () => {
    axios
      .get(
        "https://api.openweathermap.org/data/2.5/onecall?lat=" +
          this.state.lat +
          "&lon=" +
          this.state.long +
          "&units=metric&lang=hu&exclude=minutely&appid=" +
          process.env.REACT_APP_WEATHER_API_KEY
      )
      .then((response) => {
        this.setState({ forecastData: response.data, loading: false });
        this.getGeoJson(this.state.lat, this.state.long);
      })
      .catch((error) => {
        toast.error("A meterotlógiai adatbázis nem válaszol.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "colored",
        });
      });
  };

  searchClicked = (query) => {
    this.setState({
      loading: true,
    });

    axios
      .get(
        "https://api.openweathermap.org/geo/1.0/direct?q=" +
          query +
          ",,&limit=1&appid=" +
          process.env.REACT_APP_WEATHER_API_KEY
      )
      .then((response) => {
        this.setState({
          lat: response.data[0].lat,
          long: response.data[0].lon,
          city: response.data[0].name,
          center: [response.data[0].lat, response.data[0].lon],
          searchedFor: "",
        });
        this.gatherWeatherInfo();

        localStorage.setItem("previousSearch", response.data[0].name);
      })
      .catch(() => {
        toast.error("A keresett település nem található.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "colored",
        });
      });
  };

  getGeoJson = () => {
    axios
      .get(
        "https://tile.openweathermap.org/map/temp_new/0/0/0.png?appid=" +
          process.env.REACT_APP_WEATHER_API_KEY
      )
      .then((response) => {
        this.setState({
          geoJson: response,
        });
      })
      .catch(() => {
        toast.error("A meterotlógiai adatbázis nem válaszol.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "colored",
        });
      });
  };

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      this.searchClicked(this.state.searchedFor);
    }
  };

  convertTimeStamp = (timestamp) => {
    let h = new Date(timestamp).getHours();
    let m = new Date(timestamp).getMinutes();

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return h + ":" + m;
  };

  convertWindDeg = (deg) => {
    if (deg > 22 && deg <= 67) {
      return "ÉK";
    }
    if (deg > 68 && deg <= 112) {
      return "K";
    }
    if (deg > 113 && deg <= 157) {
      return "DK";
    }
    if (deg > 158 && deg <= 202) {
      return "D";
    }
    if (deg > 203 && deg <= 247) {
      return "DNy";
    }
    if (deg > 248 && deg <= 292) {
      return "Ny";
    }
    if (deg > 293 && deg <= 337) {
      return "ÉNy";
    } else {
      return "É";
    }
  };

  convertUVI = (uvi) => {
    if (uvi < 3) {
      return "Alacsony";
    }
    if (uvi < 5) {
      return "Közepes";
    }
    if (uvi < 7) {
      return "Erős";
    }
    if (uvi < 8) {
      return "Nagyon erős";
    } else {
      return "Extrém";
    }
  };

  convertMoonPhase = (moonPhase) => {
    if (moonPhase === 0 || moonPhase === 1) {
      return "Újhold";
    }
    if (moonPhase < 0.5) {
      return "Növő Hold";
    }
    if (moonPhase === 0.5) {
      return "Telihold";
    }
    if (moonPhase < 1) {
      return "Fogyó Hold";
    }
  };

  getDayName = (timestamp, locale) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(locale, { weekday: "long" });
  };

  preassureAssure = (pressure, oneHPressure) => {
    if (pressure < oneHPressure) {
      return "Növekvő";
    }
    if (pressure > oneHPressure) {
      return "Csökkenő";
    } else {
      return "Stagnáló";
    }
  };

  weatherIcons = (weatherIcon) =>
    "https://openweathermap.org/img/wn/" + weatherIcon + "@2x.png";

  updateSearchInput = (e) => {
    this.setState({ searchedFor: e.target.value });
  };

  dailyMoonIllumination = (date) => {
    let dateMoonIllumination = SunCalc.getMoonIllumination(date);
    return dateMoonIllumination.fraction;
  };

  checkIfMoonFull = (illumination) => {
    if (illumination >= 99) {
      return "Telihold";
    } else if (illumination <= 2) {
      return "Újhold";
    } else {
      return illumination + "%";
    }
  };

  render() {
    if (this.state.forecastData.length !== 0) {
      const hourlyData = this.state.forecastData.hourly.slice(1, 25);
      const weeklyData = this.state.forecastData.daily.slice(1, 8);

      // const SunCalc = require("suncalc");
      const moonTimes = SunCalc.getMoonTimes(
        new Date(),
        this.state.lat,
        this.state.long,
        true
      );

      let itsLoading = this.state.loading
        ? "Adatok betöltése"
        : "Település keresése";

      const moonIllumination = SunCalc.getMoonIllumination(new Date());

      return (
        <div className={classes.WeatherForecast}>
          <FlexBreak direction="row">
            <FlexBreak direction="column">
              <div className={classes.searchBox}>
                <Input
                  icon={<SearchIcon />}
                  type="text"
                  value={this.state.searchedFor}
                  onChange={(e) => this.updateSearchInput(e)}
                  onClick={() => this.searchClicked(this.state.searchedFor)}
                  onKeyDown={this.onKeyDown}
                >
                  {itsLoading}
                </Input>
              </div>
              <MainWeatherReport
                cityName={this.state.city}
                mainTemp={
                  Math.floor(this.state.forecastData.current.temp) + "°"
                }
                moonIllumination={this.convertMoonPhase(moonIllumination.phase)}
                pressureCheck={this.preassureAssure(
                  this.state.forecastData.hourly[0].pressure,
                  this.state.forecastData.hourly[2].pressure
                )}
              />
              <Map
                center={this.state.center}
                coordinates={[
                  this.state.forecastData.lat,
                  this.state.forecastData.lon,
                ]}
              />
            </FlexBreak>
            <FlexBreak direction="column">
              <h3>Részletes Jelentés</h3>
              <WeatherReport>
                <SubWeatherReport
                  icon={<WbSunnyOutlinedIcon />}
                  dataPoint={this.convertTimeStamp(
                    this.state.forecastData.daily[0].sunrise * 1000
                  )}
                >
                  Napkelte
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<FlareOutlinedIcon />}
                  dataPoint={this.convertTimeStamp(
                    this.state.forecastData.daily[0].sunset * 1000
                  )}
                >
                  Napnyugta
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<DeviceThermostatOutlinedIcon />}
                  dataPoint={
                    Math.floor(this.state.forecastData.daily[0].temp.max) + "°"
                  }
                >
                  Napi max
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<AirOutlinedIcon />}
                  dataPoint={
                    this.convertWindDeg(
                      this.state.forecastData.daily[0].wind_deg
                    ) +
                    " " +
                    Math.floor(
                      this.state.forecastData.daily[0].wind_speed * 3.6
                    ) +
                    " km/h"
                  }
                >
                  Szél
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<AvTimerIcon />}
                  dataPoint={this.state.forecastData.current.pressure + " hPa"}
                >
                  Légnyomás
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<BeachAccessOutlinedIcon />}
                  dataPoint={this.convertUVI(
                    this.state.forecastData.current.uvi
                  )}
                >
                  UVI
                </SubWeatherReport>
              </WeatherReport>
              <h3>Szolunáris adatok</h3>
              <WeatherReport>
                <SubWeatherReport
                  icon={<ArrowCircleUpOutlinedIcon />}
                  dataPoint={this.convertTimeStamp(moonTimes.rise)}
                >
                  Holdkelte
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<ArrowCircleDownOutlinedIcon />}
                  dataPoint={this.convertTimeStamp(moonTimes.set)}
                >
                  Holdnyugta
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<CircleOutlinedIcon />}
                  dataPoint={Math.floor(moonIllumination.fraction * 100) + "%"}
                >
                  Holdtölte
                </SubWeatherReport>
                <SubWeatherReport
                  icon={<Brightness2OutlinedIcon />}
                  dataPoint={this.convertMoonPhase(moonIllumination.phase)}
                >
                  Holdfázis
                </SubWeatherReport>
              </WeatherReport>
            </FlexBreak>
          </FlexBreak>
          <FlexBreak direction="row">
            <FlexBreak direction="column">
              <h3>24 órás előrejelzés</h3>
              <TwentyFourHTable>
                <tr>
                  {hourlyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        {this.convertTimeStamp(data.dt * 1000)}
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {hourlyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        <img
                          src={this.weatherIcons(data.weather[0].icon)}
                          alt="weather icon"
                        />
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {hourlyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        {Math.floor(data.temp) + "°"}
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {hourlyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        {Math.floor(data.pop * 100) + "%"}
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {hourlyData.map((data, i) => {
                    return <TableCell key={i}>{data.pressure}</TableCell>;
                  })}
                </tr>
                <tr>
                  {hourlyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        <div className={classes.gray}>
                          {this.convertWindDeg(data.wind_deg)}
                        </div>
                        <div>{Math.floor(data.wind_speed * 3.6) + " km/h"}</div>
                      </TableCell>
                    );
                  })}
                </tr>
              </TwentyFourHTable>
            </FlexBreak>
            <FlexBreak direction="column">
              <h3>Heti előrejelzés</h3>
              <WeeklyTable>
                <tr>
                  {weeklyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        {this.getDayName(data.dt * 1000, "hu")}
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {weeklyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        <img
                          src={this.weatherIcons(data.weather[0].icon)}
                          alt="weather icon"
                        />
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {weeklyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        <div>{Math.floor(data.temp.day) + "°"}</div>
                        <div className={classes.gray}>
                          {Math.floor(data.temp.min) + "°"}
                        </div>
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {weeklyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        {Math.floor(data.pop * 100) + "%"}
                      </TableCell>
                    );
                  })}
                </tr>
                <tr>
                  {weeklyData.map((data, i) => {
                    return <TableCell key={i}>{data.pressure}</TableCell>;
                  })}
                </tr>
                <tr>
                  {weeklyData.map((data, i) => {
                    return (
                      <TableCell key={i}>
                        <div>
                          {this.checkIfMoonFull(
                            Math.floor(
                              this.dailyMoonIllumination(data.dt * 1000) * 100
                            )
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </tr>
              </WeeklyTable>
            </FlexBreak>
          </FlexBreak>
        </div>
      );
    } else {
      return (
        <div className={classes.loading}>
          <div className={classes.loadingioSpinnerDualRingPwgiifgn0cc}>
            <div className={classes.ldioJz9hdxy7m7}>
              <div></div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default WeatherForecast;
